<template>
  <div class="content-list">

    <el-breadcrumb class="query-breadcrumb" separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">{{ dict[language].home }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ paths[0] }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-if="dataForm.navId==='1387009328948035585'&&branchId">
      <el-button @click="goBackBranch()" class="goBackBotton" type="primary" icon="el-icon-back">返回专科分会列表</el-button>
    </div>
    <div class="content-list-warp">
      <div class="content-list-left-nav" style="margin-top: 80px">
        <!--        <span>{{paths[paths.length-1]}}{{dict[language].column}}</span>-->
        <div>
          <ul>
            <li :class="{active: child.id === dataForm.navId}" v-for="child in liList" :key="child.id"
                @click="handlerSwitchColumn(child.id)">
              {{ handlerSwitchLanguage(language, 'name', child) }}
            </li>
          </ul>
        </div>
      </div>

      <div class="content-list-main">
        <div v-if="paths[0]=='关于学会'">
          <el-row type="flex" justify="center">
            <el-col :span="8"><span class="item-title">上海卒中学会</span></el-col>
          </el-row>
          <el-row type="flex" justify="center">
<!--            <el-col :span="14"><span class="item-title-en">Shanghai Stroke Association</span></el-col>-->
          </el-row>
        </div>
        <div v-if="paths[0]=='学术活动'">
          <div v-if="dataForm.navId=='1387008031733694466'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">学术活动</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="20"><span class="item-title-en">Activity InformationActivity Information</span></el-col>-->
            </el-row>
          </div>
          <div v-if="dataForm.navId=='1387008073009840130'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">通知公告</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="15"><span class="item-title-en">Notice and Announcement</span></el-col>-->
            </el-row>
          </div>
          <div v-if="dataForm.navId=='1387008231349010433'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">前沿动态</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="11"><span class="item-title-en">Frontier Dynamic</span></el-col>-->
            </el-row>
          </div>

        </div>
        <div>
          <div v-if="dataForm.navId=='1387009328948035585'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">专科分会</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="11"><span class="item-title-en">Specialist Branch</span></el-col>-->
            </el-row>
          </div>
          <div v-if="dataForm.navId=='1387009369183993857'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">分会动态</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="13"><span class="item-title-en">Member Development</span></el-col>-->
            </el-row>
          </div>
          <div v-if="dataForm.navId=='1387009447437123586'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">工作动态</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="10"><span class="item-title-en">Work Dynamics</span></el-col>-->
            </el-row>
          </div>
          <div v-if="dataForm.navId=='1387009500331491330'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">下载专区</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="12"><span class="item-title-en">Download Dynamics</span></el-col>-->
            </el-row>
          </div>
        </div>
        <div v-if="dataForm.navId=='1387007838166564865'">
          <el-row type="flex" justify="center">
            <el-col :span="8"><span class="item-title">表单下载</span></el-col>
          </el-row>
          <el-row type="flex" justify="center">
<!--            <el-col :span="12"><span class="item-title-en">Download Dynamics</span></el-col>-->
          </el-row>
        </div>
<!--        <div v-if="dataForm.navId=='1387008837983780865'">-->
<!--          <el-row type="flex" justify="center">-->
<!--            <el-col :span="8"><span class="item-title">文献查找</span></el-col>-->
<!--          </el-row>-->
<!--          <el-row type="flex" justify="center">-->
<!--            <el-col :span="12"><span class="item-title-en">Literature Search</span></el-col>-->
<!--          </el-row>-->
<!--        </div>-->
        <div v-if="dataForm.navId=='1440953594898554882'">
          <el-row type="flex" justify="center">
            <el-col :span="8"><span class="item-title">党建活动</span></el-col>
          </el-row>
          <el-row type="flex" justify="center">
<!--            <el-col :span="12"><span class="item-title-en">Party building activities</span></el-col>-->
          </el-row>
        </div>
        <div v-if="dataForm.navId=='1440953671620763650'">
          <el-row type="flex" justify="center">
            <el-col :span="8"><span class="item-title">公益活动</span></el-col>
          </el-row>
<!--          <el-row type="flex" justify="center">-->
<!--            <el-col :span="12"><span class="item-title-en">public benefit activities</span></el-col>-->
<!--          </el-row>-->
        </div>

        <div v-if="dataForm.navId=='1387008837983780865'">
          <el-row type="flex" justify="center">
            <el-col :span="20">
              <div v-if="pageStatus !== 'detail'" style="margin-top: 15px; width: 500px">
                <el-input placeholder="请输入内容" v-model="searchText">
                  <template slot="append"><el-button type="text" style="width: 80px" @click="documentSearchList()">搜索</el-button> </template>
                </el-input>
                <!-- 文献查找 -->
                <div class="query-resolve" v-if="paths.length>1 && paths[1]=='文献查找'">
                  <div v-for="data in documentList" :key="data.id" >
                    <el-row>
                      <!-- 表单下载需要单独处理 -->
                      <el-col v-if="data.navName === '表单下载'" :span="16" style="display: flex">
                        <span @click="downloadFile(data.coverImg)" pointer>{{ data.title }}</span>
                        <img @click="downloadFile(data.coverImg)" src="../../assets/images/download.png" style="width: 15px;height: 15px;margin-left: 10px"/>
                      </el-col>
                      <el-col v-else :span="22">
                        <span @click="handlerClickList(data)" pointer>{{ data.title }}</span>
                        <el-button type="text" v-if="data.status==1">【{{ dict[language].top }}】</el-button>
                      </el-col>
                      <el-col :span="2">
                        <span class="sdf" right block>{{ data.createDate.split(" ")[0] }}</span>
                      </el-col>
                      <!-- <el-col :span="4">
                        <span right block class="sdf">
                          <i class="el-icon-view"></i>
                          {{ data.readNum }}
                        </span>
                      </el-col> -->
                    </el-row>
                    <el-divider></el-divider>
                  </div>
                </div>
                <div v-if="documentList.length" class="query-pagination">
                  <el-pagination @current-change="(v) =>documentSearchList({page: v})" :current-page="documentPage"
                                  :page-size="10" layout="total, prev, pager, next"
                                  :total="documentTotal" right>
                  </el-pagination>
                </div>
              </div>
            </el-col>
          </el-row>

        </div>
        <div>
          <div v-if="dataForm.navId=='1387010046811553793'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">健康常识</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="11"><span class="item-title-en">Health Knowledge</span></el-col>-->
            </el-row>
          </div>
          <div v-if="dataForm.navId=='1387009978280820737'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">公益活动</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="15"><span class="item-title-en">Popular Science Activities</span></el-col>-->
            </el-row>
          </div>
          <div v-if="dataForm.navId=='1387010096950263810'">
            <el-row type="flex" justify="center">
              <el-col :span="8"><span class="item-title">科普项目</span></el-col>
            </el-row>
            <el-row type="flex" justify="center">
<!--              <el-col :span="14"><span class="item-title-en">Popular Science Project</span></el-col>-->
            </el-row>
          </div>
        </div>

        <section v-if="pageStatus === 'detail'">
          <div class="content-list-main-html-title">{{ curObj.title }}</div>
          <span v-if="!this.content" class="content-list-empty">
            <div>{{ dict[language].noData }}</div>
          </span>

          <div v-if="this.content.type=='1'" v-html="this.content.msg" class="content-list-detail"></div>
          <el-image v-else v-for="url in this.imgList" :src="url" lazy v-bind:key="url" class="img-list-detail"></el-image>
          <div v-if="isShowVideo" style="width:100%">
            <div class="titleSty">{{videoTitle}}</div>
            <video class="videoS" width="100%" height="450"
              autoplay="autoplay" loop="loop" muted="muted"
              preload="none" controls="controls" ref="video">
              <source :src="videoUrl"/>
            </video>
            <div v-for="(obj, index) in videoList" :key="index">
                <div class="videoBox" @click="selectViveo(obj, index)">
                  <div style="display:flex;align-items:center">
                    <img src="../../assets/images/video.png" class="videoImg"/>
                    <span>课程{{index+1}}：{{obj.title}}</span>
                  </div>
                  <span>{{obj.time}}</span>
                </div>
            </div>
          </div>
        </section>

        <section v-else-if="dataList.length > 0">
          <div class="query-resolve" v-if="paths[0]=='关于学会'">
            <div v-for="data in dataList" :key="data.id" >
              <el-row>
                <el-col :span="22">
                  <span @click="handlerClickList(data)" pointer>{{ data.title }}</span>
                  <el-button type="text" v-if="data.status==1">【{{ dict[language].top }}】</el-button>
                </el-col>
                <el-col :span="2">
                  <span class="sdf" right block>{{ data.createDate.split(" ")[0] }}</span>
                </el-col>
                <!-- <el-col :span="4">
                  <span right block class="sdf">
                    <i class="el-icon-view"></i>
                    {{ data.readNum }}
                  </span>
                </el-col> -->
              </el-row>
              <el-divider></el-divider>
            </div>
          </div>
          <div class="query-resolve" v-if="paths[0]=='业务中心'">
            <div v-for="data in dataList" :key="data.id" >
              <el-row>
                <el-col :span="22" style="display: flex">
                  <span @click="handlerClickList(data)" pointer>{{ data.title }}</span>
<!--                  <img @click="downloadFile(data.coverImg)" src="../../assets/images/download.png" style="width: 15px;height: 15px;margin-left: 10px"/>-->
<!--                  <el-button type="text" v-if="data.status==1">【{{ // dict[language].top }}】</el-button>-->
                </el-col>
                <el-col :span="2">
                  <span class="sdf" right block>{{ data.createDate.split(" ")[0] }}</span>
                </el-col>
                <!-- <el-col :span="4">
                  <span right block class="sdf">
                   <i class="el-icon-view"></i>
                   {{ data.readNum }}
                  </span>
                </el-col> -->
              </el-row>
              <el-divider></el-divider>
            </div>
          </div>
          <div class="query-resolve" v-if="paths[0]!='关于学会'&&paths[0]!='业务中心'&&dataForm.navId!=='1387009328948035585'">
            <div v-for="data in dataList" :key="data.id" >
              <el-row>
                <el-col :span="8">
                    <!-- <el-image :src="data.coverImg"  style="width: 200px;height: 160px;float: left"></el-image> -->
                </el-col>
                <el-col :span="22">
                  <span @click="handlerClickList(data)" pointer>{{ data.title }}</span>
                  <el-button type="text" v-if="data.status==1">【{{ dict[language].top }}】</el-button>
                </el-col>
                <el-col :span="2">
                  <span class="sdf" right block>{{ data.createDate.split(" ")[0] }}</span>
                </el-col>
                <!-- <el-col :span="1">
                  <span right block class="sdf">
                    <i class="el-icon-view"></i>
                    {{ data.readNum }}
                  </span>
                </el-col> -->
              </el-row>
              <el-divider></el-divider>
            </div>
          </div>

         <div class="query-pagination">
           <el-pagination @current-change="handleCurrentChange" :current-page="dataForm.page"
                          :page-size="dataForm.limit" layout="total, prev, pager, next"
                          :total="total" center>
           </el-pagination>
         </div>
        </section>
        <div v-if="dataForm.navId==='1387009328948035585'&&!branchId" class="index-list">
          <div v-for="item in branchList" :key="item.id" style="margin-bottom: 80px" class="listChild">
            <img :src="item.img" id="myimg6" class="list-pic" />
            <div class="list-a">
              <div class="buttonCenter" style="width: 180px; height: 60px;">
                <el-button type="text" @click="selectBranch(item.id)" >
                  <span class="cn-tit">{{item.nameCh}}</span>
                </el-button>
              </div>
            </div>
          </div>
        </div>

<!--        <section class="content-list-empty" v-else>-->
<!--          <div>{{ dict[language].noData }}</div>-->
<!--        </section>-->
      </div>

<!--      <div class="content-list-right-nav" v-if="pageStatus === 'detail'&&dataList.length>1">-->
<!--        <div>-->
<!--          <ul>-->
<!--            <li :class="{active: data.id === curObj.id}" v-for="(data, index) in dataList" :key="data.id"-->
<!--                @click="handlerClickList(data)">-->
<!--              <el-tooltip :content="data.title" placement="top-start">-->
<!--                <span>{{ index + 1 }}、{{ data.title }}</span>-->
<!--              </el-tooltip>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <p class="content-list-more" v-if="total > dataList.length" @click="pageStatus = 'list'">-->
<!--          {{ dict[this.language].more }}-->
<!--        </p>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import mixs from '@/mixs'
import {mapState} from 'vuex'
import dict from '@/assets/json/dictionaries.json'
import {getSwArticle, articleReadNum, documentSearch, getSwBranchList} from '@/http/request'

export default {
  name: "ContentList",
  props: ['id'],
  inject: ['language'],
  mixins: [mixs],
  data() {
    return {
      searchText:'',
      pageStatus: 'list',
      currentPage: 1,
      dataList: [],
      imgList: [],
      curObj: {},
      content: {},
      dict,
      total: 0,
      dataForm: {
        navId: '',
        page: 1,
        limit: 100
      },
      documentPage: 1, // 文献查找分页信息
      documentTotal: 0, // 文献总数
      documentList: [], // 文献列表
      branchList: [], // 专科分会列表
      branchId:'',
      noticeData: '',
      isShowVideo: false,
      videoList: [
        {
          title: '常用口服抗血小板不耐受及低反应性诊疗专家共识—葛均波 复旦大学附属中山医院',
          time: '0:13:08',
          url:'https://cjlfiles.doctorworking.cn/1.%E4%BF%AE%E6%94%B9%E7%89%88-%E8%91%9B%E5%9D%87%E6%B3%A2%E2%80%94%E2%80%94%E5%B8%B8%E7%94%A8%E5%8F%A3%E6%9C%8D%E6%8A%97%E8%A1%80%E5%B0%8F%E6%9D%BF%E4%B8%8D%E8%80%90%E5%8F%97%E5%8F%8A%E4%BD%8E%E5%8F%8D%E5%BA%94%E6%80%A7%E8%AF%8A%E7%96%97%E4%B8%93%E5%AE%B6%E5%85%B1%E8%AF%86%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E4%B8%AD%E5%B1%B1%E5%8C%BB%E9%99%A2%EF%BC%89.mp4'
        },
        {
          title: '血管性脱髓鞘中的轴突线粒体动力学—董强 复旦大学附属华山医院',
          time: '0:13:27',
          url:'https://cjlfiles.doctorworking.cn/2.%E8%91%A3%E5%BC%BA%E2%80%94%E2%80%94%E8%A1%80%E7%AE%A1%E6%80%A7%E8%84%B1%E9%AB%93%E9%9E%98%E4%B8%AD%E7%9A%84%E8%BD%B4%E7%AA%81%E7%BA%BF%E7%B2%92%E4%BD%93%E5%8A%A8%E5%8A%9B%E5%AD%A6%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E5%8D%8E%E5%B1%B1%E5%8C%BB%E9%99%A2%EF%BC%89.mp4'
        },
        {
          title: '新型抗癫痫药物的临床应用—汪昕 复旦大学附属中山医院',
          time: '0:11:59',
          url:'https://cjlfiles.doctorworking.cn/3.%E6%B1%AA%E6%98%95%E2%80%94%E2%80%94%E6%96%B0%E5%9E%8B%E6%8A%97%E7%99%AB%E7%97%AB%E8%8D%AF%E7%89%A9%E7%9A%84%E4%B8%B4%E5%BA%8A%E5%BA%94%E7%94%A8%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E4%B8%AD%E5%B1%B1%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '帕金森病（PD）睡眠障碍处理策略—刘春风 苏州大学附属第二医院',
          time: '0:07:06',
          url:'https://cjlfiles.doctorworking.cn/4.%E5%88%98%E6%98%A5%E9%A3%8E%E2%80%94%E2%80%94%E5%B8%95%E9%87%91%E6%A3%AE%E7%97%85%EF%BC%88PD%EF%BC%89%E7%9D%A1%E7%9C%A0%E9%9A%9C%E7%A2%8D%E5%A4%84%E7%90%86%E7%AD%96%E7%95%A5%EF%BC%88%E8%8B%8F%E5%B7%9E%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E7%AC%AC%E4%BA%8C%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '癫痫的诊疗及新指南解读—陈阳美 重庆医科大学附属第二医院',
          time: '0:07:06',
          url:'https://cjlfiles.doctorworking.cn/5.%E9%99%88%E9%98%B3%E7%BE%8E%E2%80%94%E2%80%94%E7%99%AB%E7%97%AB%E7%9A%84%E8%AF%8A%E7%96%97%E5%8F%8A%E6%96%B0%E6%8C%87%E5%8D%97%E8%A7%A3%E8%AF%BB%EF%BC%88%E9%87%8D%E5%BA%86%E5%8C%BB%E7%A7%91%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E7%AC%AC%E4%BA%8C%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '卒中后认知障碍管理专家共识—汪凯 安徽医科大学第一附属医院',
          time: '0:11:10',
          url:'https://cjlfiles.doctorworking.cn/6.%E6%B1%AA%E5%87%AF%E2%80%94%E2%80%94%E5%8D%92%E4%B8%AD%E5%90%8E%E8%AE%A4%E7%9F%A5%E9%9A%9C%E7%A2%8D%E7%AE%A1%E7%90%86%E4%B8%93%E5%AE%B6%E5%85%B1%E8%AF%86%EF%BC%88%E5%AE%89%E5%BE%BD%E5%8C%BB%E7%A7%91%E5%A4%A7%E5%AD%A6%E7%AC%AC%E4%B8%80%E9%99%84%E5%B1%9E%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '环状RNA与脑缺血—徐安定 暨南大学附属第一医院',
          time: '0:11:18',
          url:'https://cjlfiles.doctorworking.cn/7.%E5%BE%90%E5%AE%89%E5%AE%9A%E2%80%94%E2%80%94%E7%8E%AF%E7%8A%B6RNA%E4%B8%8E%E8%84%91%E7%BC%BA%E8%A1%80%EF%BC%88%E6%9A%A8%E5%8D%97%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E7%AC%AC%E4%B8%80%E5%8C%BB%E9%99%A2%EF%BC%89.mp4'
        },
        {
          title: '房颤消融+左心耳封堵一站式手术在心源性脑卒中的应用—李毅刚 上海交通大学医学院附属新华医院',
          time: '0:13:39',
          url:'https://cjlfiles.doctorworking.cn/8.%E6%9D%8E%E6%AF%85%E5%88%9A%E2%80%94%E2%80%94%E6%88%BF%E9%A2%A4%E6%B6%88%E8%9E%8D%2B%E5%B7%A6%E5%BF%83%E8%80%B3%E5%B0%81%E5%A0%B5%E4%B8%80%E7%AB%99%E5%BC%8F%E6%89%8B%E6%9C%AF%E5%9C%A8%E5%BF%83%E6%BA%90%E6%80%A7%E8%84%91%E5%8D%92%E4%B8%AD%E7%9A%84%E5%BA%94%E7%94%A8%EF%BC%88%E4%B8%8A%E6%B5%B7%E4%BA%A4%E9%80%9A%E5%A4%A7%E5%AD%A6%E5%8C%BB%E5%AD%A6%E9%99%A2%E9%99%84%E5%B1%9E%E6%96%B0%E5%8D%8E%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '认知障碍与脑疾病及心理疾病—刘学源 同济大学附属第十人民医院',
          time: '0:10:17',
          url:'https://cjlfiles.doctorworking.cn/9.%E5%88%98%E5%AD%A6%E6%BA%90%E2%80%94%E2%80%94%E8%AE%A4%E7%9F%A5%E9%9A%9C%E7%A2%8D%E4%B8%8E%E8%84%91%E7%96%BE%E7%97%85%E5%8F%8A%E5%BF%83%E7%90%86%E7%96%BE%E7%97%85%EF%BC%88%E5%90%8C%E6%B5%8E%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E7%AC%AC%E5%8D%81%E4%BA%BA%E6%B0%91%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '帕金森相关疾病中的蛋白沉积分子影像—王坚  复旦大学附属华山医院',
          time: '0:14:18',
          url:'https://cjlfiles.doctorworking.cn/10.%E7%8E%8B%E5%9D%9A%E2%80%94%E2%80%94%E5%B8%95%E9%87%91%E6%A3%AE%E7%9B%B8%E5%85%B3%E7%96%BE%E7%97%85%E4%B8%AD%E7%9A%84%E8%9B%8B%E7%99%BD%E6%B2%89%E7%A7%AF%E5%88%86%E5%AD%90%E5%BD%B1%E5%83%8F%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E5%8D%8E%E5%B1%B1%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '卵圆孔未闭与偏头痛—赵玉武  上海市第六人民医院',
          time: '0:09:29',
          url:'https://cjlfiles.doctorworking.cn/11.%E8%B5%B5%E7%8E%89%E6%AD%A6%E2%80%94%E2%80%94%E5%8D%B5%E5%9C%86%E5%AD%94%E6%9C%AA%E9%97%AD%E4%B8%8E%E5%81%8F%E5%A4%B4%E7%97%9B%EF%BC%88%E4%B8%8A%E6%B5%B7%E5%B8%82%E7%AC%AC%E5%85%AD%E4%BA%BA%E6%B0%91%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '卒中预防及临床科室协作诊治的现状及展望—李威 上海卒中学会',
          time: '0:09:29',
          url:'https://cjlfiles.doctorworking.cn/12.%E6%9D%8E%E5%A8%81%E2%80%94%E2%80%94%E5%8D%92%E4%B8%AD%E9%A2%84%E9%98%B2%E5%8F%8A%E4%B8%B4%E5%BA%8A%E7%A7%91%E5%AE%A4%E5%8D%8F%E4%BD%9C%E8%AF%8A%E6%B2%BB%E7%9A%84%E7%8E%B0%E7%8A%B6%E5%8F%8A%E5%B1%95%E6%9C%9B%EF%BC%88%E4%B8%8A%E6%B5%B7%E5%8D%92%E4%B8%AD%E5%AD%A6%E4%BC%9A%EF%BC%88%E4%B8%8A%E6%B5%B7%E4%B9%9D%E9%99%A2%EF%BC%89.mp4'
        },
        {
          title: '神经系统疾病与“责任抗体”—陈向军 复旦大学附属华山医院',
          time: '0:08:13',
          url:'https://cjlfiles.doctorworking.cn/13.%E9%99%88%E5%90%91%E5%86%9B%E2%80%94%E2%80%94%E7%A5%9E%E7%BB%8F%E7%B3%BB%E7%BB%9F%E7%96%BE%E7%97%85%E4%B8%8E%E2%80%9C%E8%B4%A3%E4%BB%BB%E6%8A%97%E4%BD%93%E2%80%9D%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E5%8D%8E%E5%B1%B1%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '前庭神经炎的演变史、鉴别诊断及预后—管阳太  上海交通大学医学院附属仁济医院',
          time: '0:06:30',
          url:'https://cjlfiles.doctorworking.cn/14.%E7%AE%A1%E9%98%B3%E5%A4%AA%E2%80%94%E2%80%94%E5%89%8D%E5%BA%AD%E7%A5%9E%E7%BB%8F%E7%82%8E%E7%9A%84%E6%BC%94%E5%8F%98%E5%8F%B2%E3%80%81%E9%89%B4%E5%88%AB%E8%AF%8A%E6%96%AD%E5%8F%8A%E9%A2%84%E5%90%8E%EF%BC%88%E4%B8%8A%E6%B5%B7%E4%BA%A4%E9%80%9A%E5%A4%A7%E5%AD%A6%E5%8C%BB%E5%AD%A6%E9%99%A2%E9%99%84%E5%B1%9E%E4%BB%81%E6%B5%8E%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '多发性硬化的诊断及预防—全超  复旦大学附属华山医院',
          time: '0:04:38',
          url:'https://cjlfiles.doctorworking.cn/15.%E5%85%A8%E8%B6%85%E2%80%94%E2%80%94%E5%A4%9A%E5%8F%91%E6%80%A7%E7%A1%AC%E5%8C%96%E7%9A%84%E8%AF%8A%E6%96%AD%E5%8F%8A%E9%A2%84%E9%98%B2%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E5%8D%8E%E5%B1%B1%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '梅尼埃病的诊治进展—王武庆  复旦大学附属眼耳喉鼻科医院',
          time: '0:13:16',
          url:'https://cjlfiles.doctorworking.cn/16.%E7%8E%8B%E6%AD%A6%E5%BA%86%E2%80%94%E2%80%94%E6%A2%85%E5%B0%BC%E5%9F%83%E7%97%85%E7%9A%84%E8%AF%8A%E6%B2%BB%E8%BF%9B%E5%B1%95%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E7%9C%BC%E8%80%B3%E5%96%89%E9%BC%BB%E7%A7%91%E5%8C%BB%E9%99%A2.mp4'
        },
        {
          title: '梅尼埃病的诊治进展—王武庆  复旦大学附属眼耳喉鼻科医院',
          time: '0:08:01',
          url:'https://cjlfiles.doctorworking.cn/17.%E8%B5%B5%E5%80%A9%E5%8D%8E%E2%80%94%E2%80%94%E9%98%BF%E5%B0%94%E8%8C%A8%E6%B5%B7%E9%BB%98%E7%97%85%E7%9A%84%E6%96%B0%E8%8D%AF%E7%A0%94%E5%8F%91%E6%A6%82%E5%86%B5%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E5%8D%8E%E5%B1%B1%E5%8C%BB%E9%99%A2.mp4'
        },
      ],
      videoUrl: 'https://cjlfiles.doctorworking.cn/1.%E4%BF%AE%E6%94%B9%E7%89%88-%E8%91%9B%E5%9D%87%E6%B3%A2%E2%80%94%E2%80%94%E5%B8%B8%E7%94%A8%E5%8F%A3%E6%9C%8D%E6%8A%97%E8%A1%80%E5%B0%8F%E6%9D%BF%E4%B8%8D%E8%80%90%E5%8F%97%E5%8F%8A%E4%BD%8E%E5%8F%8D%E5%BA%94%E6%80%A7%E8%AF%8A%E7%96%97%E4%B8%93%E5%AE%B6%E5%85%B1%E8%AF%86%EF%BC%88%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E4%B8%AD%E5%B1%B1%E5%8C%BB%E9%99%A2%EF%BC%89.mp4',
      videoTitle: '课程1：常用口服抗血小板不耐受及低反应性诊疗专家共识—葛均波 复旦大学附属中山医院',
    };
  },
  watch: {
    videoUrl() {
      let video = this.$refs.video;
      video.src = this.videoUrl;
    },
    id(_new) {
      if (_new) {
        this.$set(this.dataForm, 'navId', _new)
      }
    },
    "dataForm.navId"() {
      this.branchId = '';
      this.resetData();
      if (this.id === '1387009328948035585') {
        this.getBranchList()
      } else {
        const noticeData = localStorage.getItem("home_notice")
        if (noticeData){
          this.handlerClickList(JSON.parse(noticeData));
          this.getSwArticleNew();
        } else {
          this.getSwArticle();
        }
      }
      // 判断是否是学术活动
      if(this.id !=='1387008031733694466') {
        this.isShowVideo = false;
      }
      // 监听路由改变重置输入框内容
      this.documentSearchList({title: this.UsearchText,page:1})
      this.searchText = this.UsearchText;
    }
  },
  computed: {
    ...mapState({
        UsearchText: state => state.searchText,
    }),
    paths() {
      return JSON.parse(this.$route.query.paths)
    },

    menu() {
      return JSON.parse(this.$route.query.menu)
    },
    liList() {
      return this.menu.children && this.menu.children.filter(v => v.status === 1)
    }
  },

  created() {
    this.$set(this.dataForm, 'navId', this.id);
    this.$set(this.dataForm, 'lanuage', this.language === 'zh' ? 0 : 1)
    // this.getSwArticle();
  },
  methods: {
    // 选择视频
    selectViveo(obj,index) {
      this.videoUrl = obj.url;
      this.videoTitle = `课程${index+1}：${obj.title}`;
    },
    downloadFile(url){
      window.open(url);
    },
    // 请求文章列表
    getSwArticle() {
      getSwArticle(this.dataForm).then(({data}) => {
        if (data.code === 0) {
          this.dataList = data.data.list;
          this.total = data.data.total
          // this.dataForm.page++;
          /**
           * 如果只有一个值直接到详情页面
           */
          if (this.total === 1) {
            this.handlerClickList(this.dataList[0])
          }
        }
      })
    },
    // 只有一个值  不到详情页(次方法只用于首页公告列表跳过来)
    getSwArticleNew() {
      getSwArticle(this.dataForm).then(({data}) => {
        if (data.code === 0) {
          this.dataList = data.data.list;
          this.total = data.data.total
          // this.dataForm.page++;
        }
      })
    },
    // 专科分会六个模块list获取
    getBranchList() {
      getSwBranchList({navId: '1387009328948035585', limit:999, page:1}).then(({data}) => {
        if (data.code === 0) {
          this.branchList = data.data.list;
        }
      })
    },
    selectBranch(id) {
      this.branchId = id;
      getSwArticle({navId: '1387009328948035585', branchId: id, limit:999, page:1}).then(({data}) => {
        if (data.code === 0) {
          this.dataList = data.data.list;
          this.total = data.data.total
          // this.dataForm.page++;
          /**
           * 如果只有一个值直接到详情页面
           */
          if (this.total === 1) {
            this.handlerClickList(this.dataList[0])
          }
        }
      })
    },

    resetData() {
      this.$set(this.dataForm, 'limit', 10);
      this.$set(this.dataForm, 'page', 1);
      this.pageStatus = "list";
      this.total = 0;
    },

    // 点击栏目
    handlerSwitchColumn(id) {
      this.$set(this.dataForm, 'navId', id);
      this.branchId = '';
      if (id === '1387009328948035585') {
        this.getBranchList({navId:id,limit:10,page:1,lanuage:0})
      }
      this.resetData()
    },

    // 点击文章列表
    handlerClickList(obj) {
      // 名医功夫茶视频列表展示
      if (obj.id === "1475080708448702466" ) {
        this.isShowVideo = true;
      }else {
        this.isShowVideo = false;
      }
      this.curObj = obj;
      try {
        this.content = JSON.parse(this.curObj.content);
      } catch {
        this.content = {
          type: 1,
          msg: this.curObj.content
        }
      }
      if (this.content.type == "11") {
        window.open(this.content.url)
        return
      }
      if (this.content.type == "2") {
        this.imgList = this.content.msg.split(",")
      }
      this.pageStatus = "detail";
      this.articleReadNum(obj.id)
      localStorage.setItem('home_notice','')
    },

    articleReadNum(id) {
      articleReadNum(id).then(({data}) => {
        if (data.code === 0) {
          console.log("阅读累加成功")
        }
      })
    },

    handleCurrentChange(n) {
      this.$set(this.dataForm, 'page', n);
      this.getSwArticle();
    },
    // 文献查找接口
    documentSearchList (v) {
      const dataInfo = {
        page: this.documentPage,
        limit: 10,
        title: this.searchText,
        ...v
      };
      documentSearch(dataInfo).then(({data})=>{
                this.documentList = data.data.list;
                this.documentTotal = data.data.total;
                this.$store.commit('setSearchText', '');
            })
    },
    // 返回专科分会列表
    goBackBranch () {
      this.branchId = '';
      this.pageStatus = 'list';
      this.$set(this.dataForm, 'navId', '1387009328948035585');
    },
  },
  mounted() {
    if (this.id === '1387009328948035585') {
      this.getBranchList()
    }
  },
};
</script>

<style lang='less'>
.item-title {
  margin: 20px auto;
  text-align: center;
  font-size: 28px;
  color: #404040;
  font-weight: bold;
  //margin-top: 20px;
  //margin-bottom: 20px;
}
.item-title-en{
  margin: 30px auto;
  text-align: center;
  font-size: 26px;
  color: #E7ECF2;
  font-weight: normal;
  //margin-bottom: 40px;
}

.content-list {
  width: 1050px;
  margin: 0 auto;

  .query-breadcrumb {
    margin: 25px 0 40px;
  }

  .content-list-warp {
    display: flex;
    margin-bottom: 50px;

    .content-list-left-nav {
      width: 150px;

      & > span {
        display: block;
        padding-bottom: 30px;
        font-size: 15px;
        font-weight: bold;
        color: #595757;
      }

      div {
        height: 100%;
        // border-left: solid 1px #e6e6e6;
        ul {
          li {
            padding: 10px 15px;
            font-size: 13px;
            font-weight: 400;
            color: #595757;
            line-height: 43px;
            position: relative;
            border-left: solid 1px #e6e6e6;
            cursor: pointer;

            &.active {
              font-size: 15px;
              font-weight: 500;
              color: rgba(20, 153, 220, 1);

              &::before {
                width: 5px;
                height: 30px;
                content: "";
                position: absolute;
                left: 0;
                top: 16px;
                background: rgba(20, 153, 220, 1);
              }
            }
          }
        }
      }
    }

    .content-list-main {
      flex: 1;
      padding: 0 30px 30px;

      .content-list-main-html-title {
        color: #123577;
        font-size: 25px;
        line-height: 53px;
        font-weight: bold;
        min-height: 38px;
        margin-bottom: 20px;
      }

      .query-pagination {
        margin: 50px 0;
      }

      .query-resolve {
        padding-top: 73px;
        font-size: 14px;
        //padding-left: 20px;

        .sdf {
          font-size: 10px;
          color: rgba(114, 113, 113, 1);
          display: inline-flex
        }
      }

      .content-list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #999;
      }
    }

    .content-list-right-nav {
      width: 130px;
      box-sizing: border-box;
      padding-top: 45px;


      ul {
        width: 100%;

        li {
          width: 100%;
          padding: 20px 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          font-weight: 400;
          color: #595757;
          line-height: 1.5;
          position: relative;
          border-bottom: solid 1px #e6e6e6;
          cursor: pointer;

          &.active {
            font-weight: 500;
            color: rgba(20, 153, 220, 1);

            &::before {
              width: 100%;
              height: 5px;
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              background: rgba(20, 153, 220, 1);
            }
          }

          &:first-child {
            border-top: solid 1px #e6e6e6;
          }
        }
      }

      .content-list-more {
        padding: 20px 0 10px;
        text-align: right;
        font-size: 14px;
        color: rgba(20, 153, 220, 1);
        cursor: pointer;
      }
    }

    .el-divider--horizontal {
      margin: 10px 0 50px;
    }
  }

  .content-list-detail {
    margin-bottom: 50px;
    font-size: 16px;

    img {
      width: initial;
      max-width: 810px;
      height: auto;
    }

    ol, ul, p {
      padding: revert;
      margin: revert;
    }
  }

  .img-list-detail {
    font-size: 16px;

    img {
      width: initial;
      max-width: 810px;
      height: auto;
    }
  }

  .el-button {
    color: rgb(198, 54, 54);
  }
  .index-list {
    width: 840px;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .index-list .listChild {
    position: relative;
    float: left;
    width: 260px;
    margin-bottom: 100px;
    margin-right: 20px;
  }
  .list-pic {
    display: block;
    width: 260px;
    height: 157px;
    border-radius: 4px;
    box-shadow: 6px 6px 6px 6px rgb(0 0 0 / 30%);
  }
  .list-a {
    position: absolute;
    display: block;
    width: 180px;
    height: 60px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 9%);
    margin: auto;
    left: 0;
    right: 0;
    bottom: -46px;
    text-align: center;
    // padding-top: 20px;
    box-sizing: border-box;
  }
  .list-a:hover {
    background-color: #014DA4;
    color: #fff;
    .cn-tit {
      color: #fff !important;
      font-size: 18px;
    }
    .en-tit{
        color: #fff !important;
      }
  }
  .list-a span.cn-tit {
    font-size: 18px;
    color: #383a3c;
    font-weight: bold;
  }
  .list-a span {
    display: block;
  }
  .goBackBotton {
    color: #fff;
    font-size: 14px;
    background: #014DA4;
  }
  .buttonCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .videoBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #bfbfbf;
    cursor: pointer;
  }
  .videoBox :hover{
    color: #014DA4
  }
  .videoImg {
    width: 15px;
    height: 15px;
    margin-right:10px
  }
  .videoS {
    border-top-color: initial;
    border-top-style: solid;
    border-top-width: 4px;
    border-right-color: initial;
    border-right-style: solid;
    border-right-width: 4px;
    border-bottom-color: initial;
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-left-color: initial;
    border-left-style: solid;
    border-left-width: 4px;
    border-image-source: linear-gradient(rgb(20, 153, 220), rgb(40, 56, 133));
    border-image-slice: 30;
    border-image-width: 1;
    border-image-outset: 0;
    border-image-repeat: stretch;
  }
  .titleSty {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
  }
}
</style>
